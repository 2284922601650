import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const teams = [
  { value: 'Coquelicots', label: 'Coquelicots' },
  { value: 'Jonquilles', label: 'Jonquilles' },
];

const fonctions = [
  { value: 'ICUS', label: 'ICUS' },
  { value: 'Infirmier', label: 'INF' },
  { value: 'ASSC', label: 'ASSC' },
  { value: 'Soignant', label: 'Soignant' },
  { value: 'Autre', label: 'Autre' },
];

const BasicEmployeeForm = ({
  handleSubmit,
  submitName,
  setTmpEmployee,
  defaultValues,
  setID,
}) => {
  const classes = useStyles();

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [team, setTeam] = useState('Coquelicots');
  const [fonction, setFonction] = useState('Soignant');
  const [percentage, setPercentage] = useState(100);
  const [VM, setVM] = useState(4);
  const [VCMax, setVCMax] = useState(2);
  const [VCMin, setVCMin] = useState(1);
  const [Vmin, setVmin] = useState(0);
  const [isVeilleur, setIsVeilleur] = useState(false);

  const [percentageError, setPercentageError] = useState(false);
  const [VMError, setVMError] = useState(false);
  const [VCMError, setVCMError] = useState(false);
  const [VCMinError, setVCMinError] = useState(false);
  const [VminError, setVminError] = useState(false);
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    if (defaultValues) {
      const {
        id,
        name,
        lastName,
        team,
        fonction,
        percentage,
        VM,
        VCM,
        VCMin,
        Vmin,
        isVeilleur,
      } = defaultValues;
      setName(name);
      setLastName(lastName);
      setTeam(team);
      setFonction(fonction);
      setPercentage(percentage);
      setID(id);
      setVM(VM);
      setVCMax(VCM);
      setVCMin(VCMin);
      setVmin(Vmin);
      setIsVeilleur(isVeilleur);
    }
  }, [defaultValues, setID]);

  useEffect(() => {
    if (name !== null && name !== '' && lastName !== null && lastName !== '') {
      setValidation(true);
    }
    if (validation) {
      const tmpEmployee = {
        name,
        lastName,
        team,
        fonction,
        percentage,
        VM,
        VCM: VCMax,
        VCMin: VCMin,
        Vmin,
        isVeilleur,
      };
      setTmpEmployee(tmpEmployee);
    }
  }, [
    name,
    lastName,
    validation,
    team,
    fonction,
    percentage,
    VM,
    VCMax,
    setTmpEmployee,
    Vmin,
    VCMin,
    isVeilleur,
  ]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    const lastName = e.target.value.toUpperCase();
    setLastName(lastName);
  };

  const handleTeamChange = (e) => {
    setTeam(e.target.value);
  };

  const handleFonctionChange = (e) => {
    setFonction(e.target.value);
  };

  const handlePercentageChange = (e) => {
    const percentage = e.target.value;
    if (isNaN(percentage)) {
      setPercentageError(true);
    } else {
      if (percentage > 100 || percentage < 0) {
        setPercentageError(true);
      } else {
        setPercentageError(false);
        setPercentage(e.target.value);
      }
    }
  };

  const handleVCMChange = (e) => {
    const VCM = e.target.value;
    if (isNaN(VCM)) {
      setVCMError(true);
    } else {
      if (VCM > 4 || VCM < 0) {
        setVCMError(true);
      } else {
        setVCMError(false);
        setVCMax(e.target.value);
      }
    }
  };

  const handleVCMinChange = (e) => {
    const VCMin = e.target.value;
    if (isNaN(VCMin)) {
      setVCMinError(true);
    } else {
      if (VCMin > 2 || VCMin < 0) {
        setVCMinError(true);
      } else {
        setVCMinError(false);
        setVCMin(e.target.value);
      }
    }
  };

  const handleVMChange = (e) => {
    const VM = e.target.value;
    if (isNaN(VM)) {
      setVMError(true);
    } else {
      if (VM > 14 || VM < 0) {
        setVMError(true);
      } else {
        setVMError(false);
        setVM(e.target.value);
      }
    }
  };

  const handleVminChange = (e) => {
    const Vmin = e.target.value;
    if (isNaN(Vmin)) {
      setVminError(true);
    } else {
      if (Vmin > 12 || Vmin < 0) {
        setVminError(true);
      } else {
        setVminError(false);
        setVmin(e.target.value);
      }
    }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        id="f-name"
        label="Prénom"
        value={name}
        onChange={handleNameChange}
      />
      <TextField
        id="f-lastName"
        label="Nom"
        value={lastName}
        onChange={handleLastNameChange}
      />
      <TextField
        id="f-select-team"
        select
        label="Equipe"
        value={team}
        onChange={handleTeamChange}
      >
        {teams.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="f-select-fonction"
        select
        label="Fonction"
        value={fonction}
        onChange={handleFonctionChange}
      >
        {fonctions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        error={percentageError}
        helperText="0% - 100%"
        label="Pourcentage de travail"
        id="f-percentage"
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        value={percentage}
        onChange={handlePercentageChange}
      />
      <TextField
        error={VCMError}
        helperText="0 - 4 veilles consécutives"
        label="VCM"
        id="f-vcm"
        type="number"
        value={VCMax}
        onChange={handleVCMChange}
      />
      <TextField
        error={VMError}
        helperText="0-12 veilles maximal par mois"
        label="Vmax"
        id="f-vm"
        type="number"
        value={VM}
        onChange={handleVMChange}
      />
      <TextField
        error={VCMinError}
        helperText="0 - 2 veilles consécutives min."
        label="VCMin"
        id="f-vcmin"
        type="number"
        value={VCMin}
        onChange={handleVCMinChange}
      />
      <TextField
        error={VminError}
        helperText="0-14 veilles minimal par mois"
        label="Vmin"
        id="f-vmin"
        type="number"
        value={Vmin}
        onChange={handleVminChange}
      />
      <Tooltip title="Un veilleur n'effectue que des veilles">
        <FormControlLabel
          control={
            <Checkbox
              checked={isVeilleur}
              onChange={() => {
                setIsVeilleur(!isVeilleur);
              }}
              name="checkedB"
              color="primary"
            />
          }
          labelPlacement="top"
          label="Veilleur"
        />
      </Tooltip>
      <Button
        disabled={!validation}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        {submitName}
      </Button>
    </form>
  );
};

export default BasicEmployeeForm;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

const MediaCard = ({ redirect, title, children }) => {
  const history = useHistory();
  const classes = useStyles();

  const onClickHandler = () => {
    history.push(`/${redirect}`);
  };

  return (
    <Card className={classes.root} onClick={onClickHandler}>
      <CardActionArea>
        <CardContent>
          <Typography align="center" gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          {children}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default MediaCard;

export const mergeVeillesPref = (responses, prefs) => {
  const nbNurses = responses.length;
  const nbDays = responses[0].pref.length;

  const returnArr = [...prefs];

  for (let i = 0; i < nbNurses; i++) {
    const response = responses[i].pref;
    for (let j = 0; j < nbDays; j++) {
      if (prefs[i].pref[j] === 'V+') {
        returnArr[i].pref[j] = 'V+';
      } else {
        const responsej = response[j];
        if (responsej !== 0 || returnArr[i].pref[j] === 'V') {
          returnArr[i].pref[j] = responsej;
        }
      }
    }
  }
  return returnArr;
};

export const mergeSimplePref = (responses, prefs) => {
  const nbNurses = responses.length;
  const nbDays = responses[0].pref.length;

  const response = [];
  const msg = [];

  const returnArr = [...responses];

  for (let i = 0; i < nbNurses; i++) {
    const pref = prefs[i].pref;
    const response = responses[i].pref;
    for (let j = 0; j < nbDays; j++) {
      const prefj = pref[j];
      const percentage = parseInt(prefs[i].percentage);
      const responsej = response[j];
      if (
        (responsej === 1 || responsej === '1') &&
        prefj !== 0 &&
        prefj !== 'V'
      ) {
        if (percentage !== 0 && (prefj !== -1 || prefj !== '-1')) {
          msg.push(
            "Un désir de congé n'a pas pu être respecté : " +
              prefs[i].name +
              ' le ' +
              (j + 1)
          );
          console.log(
            "Un désir de congé n'a pas pu être respecté : ",
            prefs[i].name,
            ' le ',
            j + 1
          );
        } else {
          returnArr[i].pref[j] = prefj;
        }
      }
      if (responsej !== 'V' && prefj === 'V') {
        // msg.push(
        //   '- Veilles le ' +
        //     (j + 1) +
        //     ' ' +
        //     prefs[i].name +
        //     ' ' +
        //     prefs[i].fonction
        // );
        console.log('- Veilles', j + 1, prefs[i].name, prefs[i].fonction);
      }
      if (responsej === 'V' && prefj !== 'V') {
        // msg.push(
        //   '+ Veilles le ' +
        //     (j + 1) +
        //     ' ' +
        //     prefs[i].name +
        //     ' ' +
        //     prefs[i].fonction
        // );
        console.log('+ Veilles', j + 1, prefs[i].name, prefs[i].fonction);
      }
    }
  }
  response[0] = returnArr;
  response[1] = msg;

  return response;
};

import { projectFirestore } from '../firebase/config';

export const save = (monthYeard, coquelicotsList, jonquillesList) => {
  console.log('Saving : ', monthYeard.format('YYYY MMMM'));
  const id = monthYeard.format('YYYYMMMM');
  const payload = {
    year: parseInt(monthYeard.format('YYYY')),
    month: parseInt(monthYeard.format('M')),
    jonquilles: jonquillesList,
    coquelicots: coquelicotsList,
  };
  console.log('Payload : ', payload);
  const collectionRef = projectFirestore.collection('planning');
  collectionRef.doc(id).set(payload);
};

export const retrive = async (monthYeard) => {
  const id = monthYeard.format('YYYYMMMM');
  const docRef = projectFirestore.collection('planning').doc(id);

  try {
    const doc = await docRef.get();
    return doc.data();
  } catch (error) {
    console.log('Error getting document:', error);
  }
};

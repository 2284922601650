import React, { useState, Fragment } from 'react';
import { DatePicker } from '@material-ui/pickers';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
// import dayjs from 'dayjs';

import styles from '../css/MonthPicker.module.css';

const MonthPicker = ({ setMonthYear }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const RelayDate = () => {
    setMonthYear(selectedDate);
  };

  return (
    <Fragment>
      <h1> Sélectionnez un mois </h1>
      <div className={styles.maxSize350px}>
        <Container fixed>
          <DatePicker
            variant="static"
            openTo="year"
            views={['year', 'month']}
            label="Year and Month"
            helperText="Start from year selection"
            value={selectedDate}
            onChange={setSelectedDate}
          />
        </Container>
      </div>
      <Button variant="contained" color="primary" onClick={RelayDate}>
        Valider
      </Button>
    </Fragment>
  );
};

export default MonthPicker;

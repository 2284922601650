import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SetPrefTable from './SetPrefTable';
import { initDailyNeed } from '../utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const Demo = ({
  docs,
  MonthYear,
  setList,
  lastMonth,
  team,
  isHolidaysArr,
  setIsHolidaysArr,
  generateSimple,
  setBaseDueTime,
  dailyNeed,
  setDailyNeed,
  baseDueTimeArr,
  setBaseDueTimeArr,
  showAdvencedSetting,
}) => {
  const classes = useStyles();
  const [selectedShift, setSelectedShift] = useState(1);

  // Init daily need
  useEffect(() => {
    if (MonthYear) {
      const tmpArr = initDailyNeed(team, MonthYear);
      setDailyNeed(tmpArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MonthYear, team]);

  // On click handler for pref
  const onClickHandler = (index, indexPref, event) => {
    let newState = [...docs];
    let newPref = [...newState[index].pref];
    if (event.ctrlKey) {
      newPref[indexPref] = -1;
    } else if (event.altKey) {
      newPref[indexPref] = 'HS';
    } else if (event.shiftKey) {
      newPref[indexPref] = 'Vac';
    } else {
      newPref[indexPref] = newPref[indexPref] === 0 ? selectedShift : 0;
    }
    newState[index].pref = newPref;
    setList(newState);
  };

  const setVMin = (id, val) => {
    let newState = [...docs];
    const index = newState.findIndex((nurse) => nurse.id === id);
    newState[index].Vmin = val;
    setList(newState);
  };

  const handleDailyNeedsChange = (value, index) => {
    const newDailyNeed = [...dailyNeed];
    newDailyNeed[index] = value;
    setDailyNeed(newDailyNeed);
  };

  const SendPostReqSimple = async () => {
    const response = await generateSimple(team);
    setList(response);
  };

  return (
    <div>
      {docs?.length !== 0 && MonthYear && (
        <Fragment>
          <SetPrefTable
            nursesList={docs}
            onClickHandler={onClickHandler}
            selectedDate={MonthYear}
            setBaseDueTime={setBaseDueTime}
            isHolidaysArr={isHolidaysArr}
            setIsHolidaysArr={setIsHolidaysArr}
            setVMin={setVMin}
            selectedShift={selectedShift}
            setSelectedShift={setSelectedShift}
            dailyNeed={dailyNeed}
            handleDailyNeedsChange={handleDailyNeedsChange}
            baseDueTimeArr={baseDueTimeArr}
            setBaseDueTimeArr={setBaseDueTimeArr}
            lastMonth={lastMonth}
          />
          <br />
          {showAdvencedSetting && (
            <div className={classes.root}>
              <Button
                variant="contained"
                color="primary"
                onClick={SendPostReqSimple}
              >
                Générer l'horaire
              </Button>
            </div>
          )}
        </Fragment>
      )}
      <br />
    </div>
  );
};

export default Demo;

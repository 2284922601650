import React, { useState, useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#ffffff',
    background: '#121212D9',
  },
  root: {
    width: '83%',
  },
}));

const LinearProgressWithLabel = ({ value }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};

const stepToPercentage = (step) => {
  switch (step) {
    case 0:
      return 0;
    case 1:
      return 5;
    case 2:
      return 50;
    case 3:
      return 95;
    case 4:
      return 100;
    default:
      break;
  }
};

const LoadingScreen = ({ step, msg }) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const [max, setMax] = useState(10);

  useEffect(() => {
    setProgress(stepToPercentage(step - 1));
    setMax(stepToPercentage(step));
  }, [step]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= max ? max : prevProgress + Math.random() / 4
      );
    }, 200);
    return () => {
      clearInterval(timer);
    };
  }, [max]);

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <div className={classes.root}>
        <Typography variant="h2">
          {step}. {msg} :
        </Typography>
        <LinearProgressWithLabel value={progress} />
      </div>
    </Backdrop>
  );
};

export default LoadingScreen;

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import EditIcon from '@material-ui/icons/Edit';

import BasicEmployeeForm from './BasicEmployeeForm';
import CustomSnackbar from './CustomSnackbar';
import { projectFirestore } from '../firebase/config';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const UpdateEmployeeModal = ({ doc }) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [tmpEmployee, setTmpEmployee] = useState();
  const [ID, setID] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const showSnackbar = () => {
    setOpenSnackbar(true);
    setTimeout(function () {
      setOpenSnackbar(false);
    }, 3000);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = () => {
    console.log('Updated : ', ID, tmpEmployee);
    const collectionRef = projectFirestore.collection('employees');
    collectionRef.doc(ID).update(tmpEmployee);
    setOpen(false);
    showSnackbar();
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <BasicEmployeeForm
        handleSubmit={handleUpdate}
        submitName="Mettre à jour"
        setTmpEmployee={setTmpEmployee}
        defaultValues={doc}
        setID={setID}
      />
    </div>
  );

  return (
    <div>
      <EditIcon
        style={{ cursor: 'pointer' }}
        type="button"
        onClick={handleOpen}
      />
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        {body}
      </Modal>
      <CustomSnackbar
        type="info"
        msg="Employé mis à jour !"
        isOpen={openSnackbar}
      />
    </div>
  );
};

export default UpdateEmployeeModal;

export const defaultPref = (nbDays) => {
  const tmpArr = Array.from({ length: nbDays }, (_, i) => 0);
  return tmpArr;
};

export const customSort = (arr) => {
  const sortedArr = arr.sort((a, b) => {
    let x;
    let y;
    switch (a['fonction']) {
      case 'ICUS':
        x = 1;
        break;
      case 'Infirmier':
        x = 2;
        break;
      case 'ASSC':
        x = 3;
        break;
      case 'Soignant':
        x = 4;
        break;
      case 'Autre':
        x = 5;
        break;
      default:
        x = 6;
        break;
    }
    switch (b['fonction']) {
      case 'ICUS':
        y = 1;
        break;
      case 'Infirmier':
        y = 2;
        break;
      case 'ASSC':
        y = 3;
        break;
      case 'Soignant':
        y = 4;
        break;
      case 'Autre':
        y = 5;
        break;
      default:
        x = 6;
        break;
    }
    return x < y ? -1 : 1;
  });
  return sortedArr;
};

export const splitListIntoTeams = (list) => {
  const sortedArr = list.sort((a, b) => {
    return a['team'].localeCompare(b['team']);
  });

  const nbCoquelitcots = (arr) => {
    let n = 0;
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      const team = element['team'];
      if (team === 'Coquelicots') {
        n++;
      }
    }
    return n;
  };

  const coquelicotsTeam = sortedArr.splice(0, nbCoquelitcots(sortedArr));
  const jonquillesTeam = sortedArr;

  return [coquelicotsTeam, jonquillesTeam];
};

export const shuffle = (array) => {
  array.sort(() => Math.random() - 0.5);
};

export const initDailyNeed = (team, MonthYear) => {
  const tmpArr = [];
  if (team === 'Coquelicots') {
    for (let j = 0; j < MonthYear.daysInMonth(); j++) {
      switch (MonthYear.date(j).day()) {
        case 2:
          tmpArr.push(9);
          break;
        case 3:
          tmpArr.push(9);
          break;
        case 5:
        case 6:
          tmpArr.push(8);
          break;
        default:
          tmpArr.push(8);
          break;
      }
    }
  } else if (team === 'Jonquilles') {
    for (let j = 0; j < MonthYear.daysInMonth(); j++) {
      switch (MonthYear.date(j).day()) {
        case 1:
          tmpArr.push(8);
          break;
        case 3:
          tmpArr.push(8);
          break;
        case 5:
        case 6:
          tmpArr.push(7);
          break;
        default:
          tmpArr.push(7);
          break;
      }
    }
  }
  return tmpArr;
};

export const firstMondayIndex = (currentMonth) => {
  let d = 1;
  let currentDay = currentMonth.date(d);
  while (currentDay.day() !== 1) {
    d++;
    currentDay = currentMonth.date(d);
  }
  return d - 1;
};

export const firstSaterdayIndex = (currentMonth) => {
  let d = 1;
  let currentDay = currentMonth.date(d);
  while (currentDay.day() !== 6) {
    d++;
    currentDay = currentMonth.date(d);
  }
  return d - 1;
};

export const simplifyLastWeek = (lastMonth, tmpListLength) => {
  let lastWeekArr = [];
  if (lastMonth && lastMonth.length !== 0) {
    for (let i = 0; i < lastMonth.length; i++) {
      const nurseLastWeek = lastMonth[i].pref;
      const tmpShiftArr = [];
      for (let j = 0; j < nurseLastWeek.length; j++) {
        const shift = nurseLastWeek[j];
        switch (shift) {
          case 0:
            tmpShiftArr.push(0);
            break;
          case 'V':
            tmpShiftArr.push('V');
            break;
          case 'HS':
            tmpShiftArr.push('HS');
            break;
          case 'F':
            tmpShiftArr.push('Formation');
            break;
          case 'Vac':
            tmpShiftArr.push('Vac');
            break;
          default:
            tmpShiftArr.push(1);
            break;
        }
      }
      lastWeekArr.push(tmpShiftArr);
    }
  } else {
    for (let i = 0; i < tmpListLength; i++) {
      const tmpShiftArr = [0, 0, 0, 0, 0, 0, 0];
      lastWeekArr.push(tmpShiftArr);
    }
  }

  return lastWeekArr;
};

import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import About from './About';
import Home from './Home';
import Generator from './Generator';
import EmployeeManagement from './EmployeeManagement';
import LoginForm from './Login';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      )
    }
  />
);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <div className="App">
      <Switch>
        <PrivateRoute
          path="/about"
          isAuthenticated={isAuthenticated}
          component={About}
        />
        <PrivateRoute
          path="/demo"
          isAuthenticated={isAuthenticated}
          component={Generator}
        />
        <PrivateRoute
          path="/employeeManagement"
          isAuthenticated={isAuthenticated}
          component={EmployeeManagement}
        />
        <PrivateRoute
          path="/home"
          isAuthenticated={isAuthenticated}
          component={Home}
        />
        <Route path="/">
          <LoginForm setIsAuthenticated={setIsAuthenticated} />
        </Route>
      </Switch>
    </div>
  );
}

export default App;

import React, { useState, Fragment } from 'react';
import useFirestore from '../hooks/useFirestore';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import UpdateEmployeeModal from './UpdateEmployeeModal';

import DeleteIcon from '@material-ui/icons/Delete';

import CustomSnackbar from './CustomSnackbar';

import { projectFirestore } from '../firebase/config';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const EmployeesTable = ({ docs, classes, deleteOn, editOn }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleDelete = (id) => {
    console.log('Supprimer : ', id);
    const collectionRef = projectFirestore.collection('employees');
    collectionRef.doc(id).delete();
    showSnackbar();
  };

  const showSnackbar = () => {
    setOpenSnackbar(true);
    setTimeout(function () {
      setOpenSnackbar(false);
    }, 3000);
  };

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Prénom</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Equipe</TableCell>
              <TableCell>Fonction</TableCell>
              <TableCell>Pourcentage</TableCell>
              <TableCell>VCMax</TableCell>
              <TableCell>Vmax</TableCell>
              <TableCell>VCMin</TableCell>
              <TableCell>Vmin</TableCell>
              <TableCell>Veilleur</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {docs &&
              docs.map((doc) => (
                <TableRow key={doc.id}>
                  <TableCell component="th" scope="row">
                    {doc.id}
                  </TableCell>
                  <TableCell>{doc.name}</TableCell>
                  <TableCell>{doc.lastName}</TableCell>
                  <TableCell>{doc.team}</TableCell>
                  <TableCell>{doc.fonction}</TableCell>
                  <TableCell>{parseInt(doc.percentage)}%</TableCell>
                  <TableCell>{parseInt(doc.VCM)}</TableCell>
                  <TableCell>{parseInt(doc.VM)}</TableCell>
                  <TableCell>{doc.VCMin}</TableCell>
                  <TableCell>{doc.Vmin}</TableCell>
                  <TableCell>{doc.isVeilleur ? 'oui' : '-'}</TableCell>
                  {editOn && (
                    <Tooltip title="Modifier" placement="right">
                      <TableCell>
                        <UpdateEmployeeModal doc={doc} />
                      </TableCell>
                    </Tooltip>
                  )}
                  {deleteOn && (
                    <TableCell>
                      {
                        <Tooltip title="Supprimer">
                          <DeleteIcon
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => handleDelete(doc.id)}
                          />
                        </Tooltip>
                      }
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomSnackbar
        type="error"
        msg="Employé supprimé !"
        isOpen={openSnackbar}
      />
    </Fragment>
  );
};

const EmployeesList = () => {
  const [deleteOn, toggleDeletonOn] = useState(false);
  const [editOn, toogleEditOn] = useState(false);

  const { docs } = useFirestore('employees');
  const classes = useStyles();

  const sortedArr = docs.sort((a, b) => {
    return a['team'].localeCompare(b['team']);
  });

  return (
    <Fragment>
      <h2> Liste des employés</h2>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={deleteOn}
              onChange={() => toggleDeletonOn(!deleteOn)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          name="checkedDelete"
          label="Activer la suppression"
        />
        <FormControlLabel
          control={
            <Switch
              checked={editOn}
              onChange={() => toogleEditOn(!editOn)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          name="checkedDelete"
          label="Activer l'édition"
        />
      </FormGroup>

      <EmployeesTable
        docs={sortedArr}
        classes={classes}
        deleteOn={deleteOn}
        editOn={editOn}
      />
    </Fragment>
  );
};

export default EmployeesList;

import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const CustomSnackbar = ({ type, msg, isOpen }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    setOpenSnackbar(isOpen);
    return () => {
      setOpenSnackbar(false);
    };
  }, [isOpen]);

  return (
    <Snackbar
      open={openSnackbar}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={type}>
        {msg}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;

import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { projectFirebase } from './firebase/config';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [pswd, setPswd] = useState('');
  const [errorMsg, setErrorMsg] = useState();
  const [redirect, setRedirect] = useState(false);

  const classes = useStyles();

  const loginSubmit = (event) => {
    event.preventDefault();
    projectFirebase
      .auth()
      .signInWithEmailAndPassword(email, pswd)
      .then((user) => {
        // Signed in
        console.log(user);
        setIsAuthenticated(true);
        setRedirect(true);
      })
      .catch((error) => {
        const errorCode = error.code.concat(' : ');
        const errorMessage = error.message;
        const msg = errorCode.concat(errorMessage);
        setErrorMsg(msg);
      });
  };

  return (
    <Layout navName="Connection">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={loginSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              autoFocus
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={pswd}
              onChange={(e) => {
                setPswd(e.target.value);
              }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              type="submit"
            >
              Sign In
            </Button>
          </form>
        </div>
        {setErrorMsg && errorMsg}
      </Container>
      {redirect && <Redirect to="/home" />}
    </Layout>
  );
};

export default Login;

import React from 'react';
import Layout from './components/layout/Layout';
import AddEmployeeForm from './components/AddEmployeeForm';
import EmployeesList from './components/EmployeeList';

const EmployeeMangement = () => {
  return (
    <Layout navName="Gestion des employés">
      <AddEmployeeForm />
      <EmployeesList />
    </Layout>
  );
};

export default EmployeeMangement;

import React, { useState, useEffect, Fragment } from 'react';
import useFirestore from './hooks/useFirestore';
import axios from 'axios';

import Layout from './components/layout/Layout';
import Grid from '@material-ui/core/Grid';
import Demo from './components/Demo';
import MonthPicker from './components/MonthPicker';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';

import CustomSnackbar from './components/CustomSnackbar';
import BasicLoadingScreen from './components/BasicLoadingScreen';
import AutomatedLoadingScreen from './components/AutomatedLoadingScreen';

import {
  defaultPref,
  customSort,
  splitListIntoTeams,
  firstMondayIndex,
  firstSaterdayIndex,
  simplifyLastWeek,
} from './utils/utils';
import { mergeVeillesPref, mergeSimplePref } from './utils/merging';
import { generatePiquets, generateShifts } from './utils/shiftsAttribution';
import { save, retrive } from './utils/firestore';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const Generator = () => {
  const classes = useStyles();
  const [MonthYear, setMonthYear] = useState(); // dayjs format day
  const [isHolidaysArr, setIsHolidaysArr] = useState([]); // array with boolean to know if a date is a holidays
  const [baseDueTime, setBaseDueTime] = useState(); // base due time in days

  const [allList, setAllList] = useState([]);
  const [coquelicotsList, setCoquelicotsList] = useState([]);
  const [jonquillesList, setJonquillesList] = useState([]);
  const [dailyNeedCoquelicots, setDailyNeedCoquelicots] = useState([]);
  const [dailyNeedJonquilles, setDailyNeedJonquilles] = useState([]);
  const [baseDueTimeArrC, setBaseDueTimeArrC] = useState([]);
  const [baseDueTimeArrJ, setBaseDueTimeArrJ] = useState([]);

  const [lastMonthCoquelicots, setLastMonthCoquelicots] = useState();
  const [lastMonthJonquilles, setLastMonthJonquilles] = useState();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [step, setStep] = useState(0);
  const [loadingMsg, setLoadingMsg] = useState('');
  const [showAdvencedSetting, setShowAdvencedSetting] = useState(false);
  const [generationTime, setGenerationTime] = useState(0);
  const [reportJonquilles, setReportJonquilles] = useState([]); // array of string
  const [reportCoquelicots, setReportCoquelicots] = useState([]); // array of string

  // const URL = 'http://localhost:5001/';
  const URL = 'https://tb-app-solver.herokuapp.com/';

  const showSnackbar = () => {
    setOpenSnackbar(true);
    setTimeout(function () {
      setOpenSnackbar(false);
    }, 3000);
  };

  const updateReport = (newMsg, team) => {
    let newReport = [];
    let report = [];
    if (team === 'Coquelicots') {
      report = reportCoquelicots;
    } else {
      report = reportJonquilles;
    }
    report.forEach((msg) => newReport.push(msg));
    newMsg.forEach((msg) => newReport.push(msg));
    if (team === 'Coquelicots') {
      setReportCoquelicots(newReport);
    } else {
      setReportJonquilles(newReport);
    }
  };

  const { docs } = useFirestore('employees');

  // Init default pref
  useEffect(() => {
    if (docs.length !== 0) {
      let documents = [];
      docs.forEach((doc) => {
        if (MonthYear) {
          documents.push({
            ...doc,
            pref: defaultPref(MonthYear.daysInMonth()),
          });
        }
      });
      const toDisplay = customSort(documents);
      setAllList(toDisplay);
    }
  }, [docs, MonthYear]);

  // Split all list into the two teams
  useEffect(() => {
    // We use JSON.parse/stringify to deep copy a nested array/object
    const copyOfAllList = JSON.parse(JSON.stringify(allList));
    const splitArray = splitListIntoTeams(copyOfAllList);
    setCoquelicotsList(splitArray[0]);
    setJonquillesList(splitArray[1]);
  }, [allList]);

  // Load previous month data if exist
  useEffect(() => {
    const retriveLastMonth = async (lastMonth) => {
      const data = await retrive(lastMonth);

      if (data) {
        console.log('last month retrived :', data);
        const jonquilles = customSort(data.jonquilles);
        jonquilles.forEach((doc) => {
          doc.pref = doc.pref.slice(-7);
        });

        const coqueliccots = customSort(data.coquelicots);
        coqueliccots.forEach((doc) => {
          doc.pref = doc.pref.slice(-7);
        });

        const matched = (lastWeek, currMonth) => {
          const matchedArr = Array(currMonth.length);
          for (let i = 0; i < currMonth.length; i++) {
            const nurse = currMonth[i];
            const nurseId = nurse.id;
            const indexInLastWeekArray = lastWeek.findIndex(
              (el) => el.id === nurseId
            );
            matchedArr[i] =
              indexInLastWeekArray !== -1
                ? lastWeek[indexInLastWeekArray]
                : { pref: [0, 0, 0, 0, 0, 0, 0] };
          }
          return matchedArr;
        };

        const matchedJonquilles = matched(jonquilles, jonquillesList);
        const matchedCoquelicots = matched(coqueliccots, coquelicotsList);

        setLastMonthCoquelicots(matchedCoquelicots);
        setLastMonthJonquilles(matchedJonquilles);
      } else {
        console.log('No planning found for last month');
      }
    };
    if (MonthYear && allList.length !== 0) {
      const monthIndex = MonthYear.month();
      const lastMonth = MonthYear.month(monthIndex - 1);
      retriveLastMonth(lastMonth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MonthYear, coquelicotsList?.length]);

  const genPiquets = async (list) => {
    const withPiquets = await generatePiquets(list, MonthYear.daysInMonth());
    const toDisplay = customSort(withPiquets);
    return toDisplay;
  };

  const callGeneratePiquets = async () => {
    // We use JSON.parse/stringify to deep copy a nested array/object
    const copyOfCoquelicotsList = JSON.parse(JSON.stringify(coquelicotsList));
    const copyOfJonquillesList = JSON.parse(JSON.stringify(jonquillesList));

    const copyOfCombinedArr = copyOfCoquelicotsList.concat(
      copyOfJonquillesList
    );
    const responsePiquets = await genPiquets(copyOfCombinedArr);
    setAllList(responsePiquets);
  };

  const genVeilles = async () => {
    console.log('Generating Veilles');
    const combinedArr = coquelicotsList.concat(jonquillesList);
    // saving a copy of the Preference before sending to solver
    const copyOfCombinedArr = [...combinedArr];

    const baseDueTimeArr = baseDueTimeArrC?.concat(baseDueTimeArrJ);

    const combinedLastWeek = lastMonthCoquelicots.concat(lastMonthJonquilles);
    const lastWeekArr = simplifyLastWeek(combinedLastWeek, combinedArr.length);
    const nursesPref = JSON.stringify(combinedArr);
    const lastWeekJSON = JSON.stringify(lastWeekArr);
    const baseDueTimeArray = JSON.stringify(baseDueTimeArr);

    const payload = {
      nursesList: nursesPref,
      lastWeekJSON: lastWeekJSON,
      relaxLevel: 0,
      baseDueTimeArray: baseDueTimeArray,
    };
    console.log('payload :', payload);
    let response;
    try {
      response = await axios.post(URL + 'veilles', payload);
    } catch (e) {
      console.log('Relax veilles solver from 0 to 1');
      payload.relaxLevel = 1;
      try {
        response = await axios.post(URL + 'veilles', payload);
      } catch (e) {
        console.log('The solver failed to solve this problem.');
        setIsLoading(false);
      }
    }

    if (response?.data) {
      // custom sort after combining Pref array with response.data
      const mergedArr = mergeVeillesPref(response.data, copyOfCombinedArr);
      const toDisplay = customSort(mergedArr);
      console.log(response.data[0]['time']);
      return toDisplay;
    }
  };

  const generateVeilles = async () => {
    showSnackbar();
    setIsLoading(true);
    const responseVeilles = await genVeilles();
    setAllList(responseVeilles);
    if (responseVeilles) {
      setIsLoading(false);
    }
  };

  const generateSimpleModel = async (team) => {
    console.log('Sending a request to the Solver');
    let tmpList;
    let lastMonth;
    let dailyNeed;
    let baseDueTimeArr;
    if (team === 'Coquelicots') {
      tmpList = coquelicotsList;
      lastMonth = lastMonthCoquelicots;
      dailyNeed = dailyNeedCoquelicots;
      baseDueTimeArr = baseDueTimeArrC;
    } else if (team === 'Jonquilles') {
      tmpList = jonquillesList;
      lastMonth = lastMonthJonquilles;
      dailyNeed = dailyNeedJonquilles;
      baseDueTimeArr = baseDueTimeArrJ;
    }

    const nursesPref = JSON.stringify(tmpList);
    const dailyNeedArr = JSON.stringify(dailyNeed);
    const baseDueTimeArray = JSON.stringify(baseDueTimeArr);

    const copyOfPrefs = [...tmpList];
    const lastWeekArr = simplifyLastWeek(lastMonth, tmpList.length);
    const lastWeekJSON = JSON.stringify(lastWeekArr);

    const payload = {
      nursesList: nursesPref,
      lastWeekJSON: lastWeekJSON,
      baseDueTime: baseDueTime,
      dailyNeedArr: dailyNeedArr,
      baseDueTimeArray: baseDueTimeArray,
      firstMondayIndex: firstMondayIndex(MonthYear),
      firstSaterdayIndex: firstSaterdayIndex(MonthYear),
      relaxLevel: 0,
    };
    console.log('payload :', payload);
    let response;
    try {
      response = await axios.post(URL + 'simple', payload);
    } catch (e) {
      console.log('Relaxing parameter : set relax level from 0 to 1');
      payload.relaxLevel = 1;
      try {
        response = await axios.post(URL + 'simple', payload);
      } catch (e) {
        console.log('Relaxing parameter : set relax level from 1 to 2');
        payload.relaxLevel = 2;
        try {
          response = await axios.post(URL + 'simple', payload);
        } catch (e) {
          console.log('Relaxing parameter : set relax level from 2 to 3');
          payload.relaxLevel = 3;
          try {
            response = await axios.post(URL + 'simple', payload);
          } catch (e) {
            console.log('Relaxing parameter : set relax level from 3 to 4');
            payload.relaxLevel = 4;
            try {
              response = await axios.post(URL + 'simple', payload);
            } catch (e) {
              console.log('The solver failed to solve this problem.');
              setIsLoading(false);
            }
          }
        }
      }
    }

    if (response?.data) {
      const merged = mergeSimplePref(response.data, copyOfPrefs);
      const mergedArr = merged[0];
      const msg = merged[1];
      updateReport(msg, team);
      const toDisplay = customSort(mergedArr);
      console.log(response.data[0]['time']);
      return toDisplay;
    }
  };

  const generateSimple = async (team) => {
    showSnackbar();
    setIsLoading(true);
    const responseSimpleModel = await generateSimpleModel(team);
    if (responseSimpleModel) {
      setIsLoading(false);
    }
    return responseSimpleModel;
  };

  const genFill = async (list) => {
    const withShifts = await generateShifts(list, MonthYear.daysInMonth());
    const toDisplay = customSort(withShifts);
    return toDisplay;
  };

  const generateFill = async () => {
    // We use JSON.parse/stringify to deep copy a nested array/object
    const copyOfAllList = JSON.parse(
      JSON.stringify(coquelicotsList.concat(jonquillesList))
    );
    const responseFill = await genFill(copyOfAllList);
    setAllList(responseFill);
  };

  const genAutomatique = async () => {
    setIsLoading2(true);
    setStep(1);
    setLoadingMsg('Génération des veilles');
    const t0 = performance.now();
    const responseVeilles = await genVeilles();
    setAllList(responseVeilles);
    if (responseVeilles) {
      setStep(2);
      setLoadingMsg('Génération Coquelicots');
      const responseCoquelicots = await generateSimpleModel('Coquelicots');
      if (responseCoquelicots) {
        setStep(3);
        setLoadingMsg('Génération Jonquilles');
      }
      const responseJonquilles = await generateSimpleModel('Jonquilles');
      if (responseJonquilles) {
        setStep(4);
        setLoadingMsg('Attribution des horaires et piquets');
      }
      if (responseCoquelicots && responseJonquilles) {
        setAllList(responseCoquelicots.concat(responseJonquilles));
        const copyOfAllList = responseCoquelicots.concat(responseJonquilles);
        const responseFill = await genFill(copyOfAllList);
        setAllList(responseFill);
        if (responseFill) {
          const responsePiquets = await genPiquets(responseFill);
          setAllList(responsePiquets);
          if (responsePiquets) {
            const validation = await genVeilles();
            if (validation) {
              setStep(4);
              const t1 = performance.now();
              setGenerationTime(((t1 - t0) / 1000).toFixed(2));
              console.log(
                'Génération complète terminée en : ' +
                  ((t1 - t0) / 1000).toFixed(2) +
                  ' seconds.'
              );
            } else {
              console.log(
                'Génération terminée mais les veilles ne sont pas valides'
              );
            }
            setIsLoading2(false);
          }
        }
      }
    }
  };

  const load = async () => {
    const data = await retrive(MonthYear);
    if (data) {
      console.log('Document retrived :', data);
      const jonquilles = data.jonquilles;
      const coqueliccots = data.coquelicots;
      setCoquelicotsList(coqueliccots);
      setJonquillesList(jonquilles);
      setReportJonquilles([]);
      setReportCoquelicots([]);
      setGenerationTime(0);
    } else {
      console.log('No such document!');
    }
  };

  return (
    <Layout navName="Générateur">
      {!MonthYear ? (
        <MonthPicker setMonthYear={setMonthYear} />
      ) : (
        <Fragment>
          <h1> Planning : {MonthYear.format('MMMM YYYY')}</h1>
          <div className={classes.root}>
            <Button
              variant="contained"
              color="primary"
              onClick={genAutomatique}
            >
              Génération automatique
            </Button>
            <FormControlLabel
              control={
                <Switch
                  checked={showAdvencedSetting}
                  onChange={() => setShowAdvencedSetting(!showAdvencedSetting)}
                />
              }
              label="Options avancées"
            />
            <Collapse in={showAdvencedSetting}>
              <Button
                variant="contained"
                color="primary"
                onClick={generateVeilles}
              >
                Veilles
              </Button>{' '}
              <Button
                variant="contained"
                color="primary"
                onClick={generateFill}
              >
                Attribuer les horaires
              </Button>{' '}
              <Button
                variant="contained"
                color="primary"
                onClick={callGeneratePiquets}
              >
                Piquets
              </Button>
            </Collapse>

            <Button
              variant="contained"
              color="primary"
              onClick={() => save(MonthYear, coquelicotsList, jonquillesList)}
            >
              Sauvegarder
            </Button>
            <Button variant="contained" color="primary" onClick={load}>
              Charger
            </Button>
          </div>
        </Fragment>
      )}
      <Demo
        docs={coquelicotsList}
        MonthYear={MonthYear}
        team="Coquelicots"
        setList={setCoquelicotsList}
        lastMonth={lastMonthCoquelicots}
        isHolidaysArr={isHolidaysArr}
        setIsHolidaysArr={setIsHolidaysArr}
        generateSimple={generateSimple}
        setBaseDueTime={setBaseDueTime}
        dailyNeed={dailyNeedCoquelicots}
        setDailyNeed={setDailyNeedCoquelicots}
        baseDueTimeArr={baseDueTimeArrC}
        setBaseDueTimeArr={setBaseDueTimeArrC}
        showAdvencedSetting={showAdvencedSetting}
      />
      <Demo
        docs={jonquillesList}
        MonthYear={MonthYear}
        team="Jonquilles"
        setList={setJonquillesList}
        lastMonth={lastMonthJonquilles}
        isHolidaysArr={isHolidaysArr}
        setIsHolidaysArr={setIsHolidaysArr}
        generateSimple={generateSimple}
        dailyNeed={dailyNeedJonquilles}
        setDailyNeed={setDailyNeedJonquilles}
        baseDueTimeArr={baseDueTimeArrJ}
        setBaseDueTimeArr={setBaseDueTimeArrJ}
        showAdvencedSetting={showAdvencedSetting}
      />
      {isLoading && <BasicLoadingScreen />}
      {isLoading2 && <AutomatedLoadingScreen step={step} msg={loadingMsg} />}
      {generationTime > 0 && (
        <div> La génération a pris : {generationTime} secondes</div>
      )}
      {generationTime > 0 && (
        <Grid container spacing={2}>
          <Grid item>
            <h2>Coquelicots</h2>
            {reportCoquelicots.map((msg, index) => (
              <p key={index}>{msg}</p>
            ))}
          </Grid>
          <Grid item>
            <h2>Jonquilles</h2>
            {reportJonquilles.map((msg, index) => (
              <p key={index}>{msg}</p>
            ))}
          </Grid>
        </Grid>
      )}
      <CustomSnackbar
        type="info"
        msg="Requête envoyée au solveur"
        isOpen={openSnackbar}
      />
    </Layout>
  );
};

export default Generator;

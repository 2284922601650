import * as firebase from 'firebase/app';
import 'firebase/firebase-firestore';
import 'firebase/auth';

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: 'AIzaSyCDXquyH-ggXUv2A8ny-iFIGh-m8z_Ac2s',
  authDomain: 'tb-app-5bae6.firebaseapp.com',
  databaseURL: 'https://tb-app-5bae6.firebaseio.com',
  projectId: 'tb-app-5bae6',
  storageBucket: 'tb-app-5bae6.appspot.com',
  messagingSenderId: '368356583385',
  appId: '1:368356583385:web:bacd69d617d7893879dbc8',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectFirebase = firebase;
const projectFirestore = firebase.firestore();

export { projectFirestore, projectFirebase };

import React from 'react';
import Layout from './components/layout/Layout';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import EventIcon from '@material-ui/icons/Event';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import Card from './components/Card';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <Layout navName="Dragonfruit">
      <Grid
        container
        className={classes.root}
        spacing={10}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <div>
            <Card redirect="Demo" title="Générer un horaire">
              <EventIcon style={{ fontSize: 300 }} />
            </Card>
          </div>
        </Grid>
        <Grid item>
          <div>
            <Card redirect="employeeManagement" title="Gestion des employées">
              <SupervisorAccountIcon style={{ fontSize: 300 }} />
            </Card>
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Home;

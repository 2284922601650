import { shuffle, splitListIntoTeams } from './utils';

export const generatePiquets = async (list, daysInMonth) => {
  console.log('Attribution des piquets');
  // We use JSON.parse/stringify to deep copy a nested array/object
  const combinedArr = JSON.parse(JSON.stringify(list));
  // Each day, check if a Infirmier has a night shift or piquet was already assign : set needPiquet[j] to true or false
  let needPiquet = [];
  for (let j = 0; j < daysInMonth; j++) {
    let needP = true;
    for (let i = 0; i < combinedArr.length; i++) {
      const c = combinedArr[i];
      const cPref = c['pref'][j];
      const cFonction = c['fonction'];
      if (
        (cPref === 'V' || cPref === 'QP') &&
        (cFonction === 'Infirmier' || cFonction === 'ICUS')
      ) {
        needP = false;
      }
    }
    needPiquet.push(needP);
  }
  console.log("Besoin d'un piquet : ", needPiquet);

  // Each day, if needPiquet[j] = true, assign piquet to a nurse with a Q shift
  for (let j = 0; j < daysInMonth; j++) {
    if (needPiquet[j]) {
      let infirmierQ = [];
      for (let i = 0; i < combinedArr.length; i++) {
        const c = combinedArr[i];
        const cPref = c['pref'][j];
        const cFonction = c['fonction'];
        if (
          cPref === 'Q' &&
          (cFonction === 'Infirmier' || cFonction === 'ICUS')
        ) {
          infirmierQ.push(i);
        }
      }

      if (infirmierQ.length !== 0) {
        const prioPiquet = [];
        for (let i = 0; i < infirmierQ.length; i++) {
          const index = infirmierQ[i];
          if (j - 1 >= 0 && combinedArr[index].pref[j - 1] !== 'QP') {
            prioPiquet.push(index);
          }
        }

        if (prioPiquet.length !== 0) {
          const randIndex =
            prioPiquet[Math.floor(Math.random() * prioPiquet.length)];
          combinedArr[randIndex]['pref'][j] = 'QP';
        } else {
          const randIndex =
            infirmierQ[Math.floor(Math.random() * infirmierQ.length)];
          combinedArr[randIndex]['pref'][j] = 'QP';
        }
      }
    }
  }

  return combinedArr;
};

export const generateShifts = async (list, daysInMonth) => {
  console.log('Filling the shifts');
  // We use JSON.parse/stringify to deep copy a nested array/object
  const combinedArr = JSON.parse(JSON.stringify(list));

  // Attribuer un Q et un I aux ICUS/infirmiers sur les deux équipes

  // Chaque jour
  const nbDays = daysInMonth;
  for (let j = 0; j < nbDays; j++) {
    // Faire la liste de tous les ICUS et infirmiers (sur les deux équipes) qui travaillent
    const listOfInfirmier = [];
    for (let i = 0; i < combinedArr.length; i++) {
      const nurse = combinedArr[i];
      const fonction = nurse['fonction'];
      const isWorking = nurse['pref'][j];
      if (
        (fonction === 'ICUS' || fonction === 'Infirmier') &&
        (isWorking === '1' || isWorking === 1)
      ) {
        listOfInfirmier.push(i);
      }
    }
    // listOfInfirmier contient la liste de tous les infirmiers qui travaillent

    // Vérifier si c'est le dernier jour d'un des infirmiers qui travaillent
    // Ou si il fait une veille le lendemain
    const priorityGarde = [];
    if (listOfInfirmier.length !== 0) {
      for (let i = 0; i < listOfInfirmier.length; i++) {
        if (j + 1 <= nbDays) {
          const index = listOfInfirmier[i];
          const nextShift = combinedArr[index]['pref'][j + 1];
          if (nextShift === 0 || nextShift === '0' || nextShift === 'V') {
            priorityGarde.push(index);
          }
        }
        if (priorityGarde.length === 0) {
          if (j + 2 <= nbDays && j - 1 >= 0) {
            const index = listOfInfirmier[i];
            const lastShift = combinedArr[index]['pref'][j - 1];
            const nextNextShift = combinedArr[index]['pref'][j + 2];
            if (
              (nextNextShift !== 0 || nextNextShift !== '0') &&
              lastShift !== 'I'
            ) {
              priorityGarde.push(index);
            }
          }
        }
      }

      // Mélanger la liste des infirmiers
      shuffle(listOfInfirmier);

      // Si oui, (priorityGarde n'est pas vide), donner le I (priorityGarde)
      if (priorityGarde.length !== 0) {
        const randIndex =
          priorityGarde[Math.floor(Math.random() * priorityGarde.length)];
        combinedArr[randIndex]['pref'][j] = 'I';
        // Enlever l'infirmier de la liste
        const toDelete = listOfInfirmier.indexOf(randIndex);
        if (toDelete !== -1) {
          listOfInfirmier.splice(toDelete, 1);
        }
        // Si non, donner le I à n'importe qui (dans listOfInfirmier) en prio éviter les Q de suite
        // Donner le I en prio à l'Inf qui a fait un Q la veille
      } else {
        const qLaVeille = [];
        if (listOfInfirmier.length !== 0) {
          for (let i = 0; i < listOfInfirmier.length; i++) {
            if (j - 1 >= 0) {
              const index = listOfInfirmier[i];
              const lastShift = combinedArr[index]['pref'][j - 1];
              if (lastShift === 'Q') {
                qLaVeille.push(index);
              }
            }
          }
        }
        if (qLaVeille.length !== 0) {
          const randIndex =
            qLaVeille[Math.floor(Math.random() * qLaVeille.length)];
          combinedArr[randIndex]['pref'][j] = 'I';
          // Enlever l'infirmier de la liste
          const toDelete = listOfInfirmier.indexOf(randIndex);
          if (toDelete !== -1) {
            listOfInfirmier.splice(toDelete, 1);
          }
        } else {
          const randIndex = listOfInfirmier.pop();
          combinedArr[randIndex]['pref'][j] = 'I';
        }
      }

      // Donner le Q à n'importe qui (dans listOfInfirmier)
      // En prio si pas eu de Q la veille
      const pasQLaVeille = [];
      if (listOfInfirmier.length !== 0) {
        for (let i = 0; i < listOfInfirmier.length; i++) {
          if (j - 1 >= 0) {
            const index = listOfInfirmier[i];
            const lastShift = combinedArr[index]['pref'][j - 1];
            if (lastShift !== 'Q') {
              pasQLaVeille.push(index);
            }
          }
        }
      }
      if (pasQLaVeille.length !== 0) {
        const randIndex =
          pasQLaVeille[Math.floor(Math.random() * pasQLaVeille.length)];
        combinedArr[randIndex]['pref'][j] = 'Q';
        // Enlever l'infirmier de la liste
        const toDelete = listOfInfirmier.indexOf(randIndex);
        if (toDelete !== -1) {
          listOfInfirmier.splice(toDelete, 1);
        }
      } else {
        const randIndex = listOfInfirmier.pop();
        if (randIndex > 0) {
          combinedArr[randIndex]['pref'][j] = 'Q';
        }
      }
    }
  }

  const splitArray = splitListIntoTeams(combinedArr);
  const coquelicotsList = generateShiftsCoquelicots(splitArray[0], daysInMonth);
  const jonquillesList = generateShiftsJonquilles(splitArray[1], daysInMonth);

  return coquelicotsList.concat(jonquillesList);
  // return combinedArr;
};

const generateShiftsCoquelicots = (teamArr, daysInMonth) => {
  // chaque jour
  const nbDays = daysInMonth;
  for (let j = 0; j < nbDays; j++) {
    // Déterminer le nombre de personne qui travaillent ce jour-là
    const allShit = ['I', 'Z', 'G', 'C', 'Q', '1', 1];
    let nbWorking = 0;
    for (let i = 0; i < teamArr.length; i++) {
      const nurse = teamArr[i];
      const shift = nurse['pref'][j];
      // Vérifier si nurse travaille
      if (allShit.includes(shift)) {
        nbWorking++;
      }
    }
    // Définition des besoins de base
    let baseNeed = [];
    if (nbWorking > 7) {
      baseNeed = ['I', 'I', 'G', 'G', 'C', 'Q', 'Z', 'I'];
    } else {
      baseNeed = ['I', 'I', 'G', 'G', 'C', 'Q', 'Z'];
    }

    // Update besoin de base selon ce qui est déjà en place
    for (let i = 0; i < teamArr.length; i++) {
      const nurse = teamArr[i];
      const shift = nurse['pref'][j];
      // Vérifier si ce n'est pas 0 ou 1 ou horaire particulier
      // Si oui le supprimer des besoins de base
      let baseNeedIndex = baseNeed.indexOf(shift);
      if (shift === 'QP') {
        baseNeedIndex = baseNeed.indexOf('Q');
      }
      if (baseNeedIndex !== -1) {
        baseNeed.splice(baseNeedIndex, 1);
      }
    }

    // Faire la liste des infirmiers/icus/assc
    const lstOfDiplome = [];
    for (let i = 0; i < teamArr.length; i++) {
      const element = teamArr[i];
      const fonction = element['fonction'];
      const pref = element['pref'][j];
      if (
        (fonction === 'ICUS' ||
          fonction === 'Infirmier' ||
          fonction === 'ASSC') &&
        (pref === '1' || pref === 1)
      ) {
        lstOfDiplome.push(i);
      }
    }

    // Check si Q est tjrs présent et si oui le donner à un infirmier/assc/icus puis le supprimer
    if (baseNeed.includes('Q')) {
      const randIndex = Math.floor(Math.random() * lstOfDiplome.length);
      const randDiplomeIndex = lstOfDiplome[randIndex];
      teamArr[randDiplomeIndex]['pref'][j] = 'Q';
      const Qindex = baseNeed.indexOf('Q');
      baseNeed.splice(Qindex, 1);
      lstOfDiplome.splice(randIndex, 1);
    }

    // Faire la liste des gens qui ont un 1
    let isWorking = [];
    for (let i = 0; i < teamArr.length; i++) {
      const nurse = teamArr[i];
      const isWorkingToday = nurse['pref'][j];
      if (isWorkingToday === 1 || isWorkingToday === '1') {
        isWorking.push(i);
      }
    }

    // Faire la liste des gens dont c'est le dernier jour
    // ou qui ont une veille le lendemain
    const priorityGarde = [];
    for (let i = 0; i < isWorking.length; i++) {
      if (j + 1 <= nbDays && j - 2 >= 0) {
        const index = isWorking[i];
        const nextShift = teamArr[index]['pref'][j + 1];
        const lastLastShift = teamArr[index]['pref'][j - 2];
        if (nextShift === 'V') {
          priorityGarde.push(index);
        }
        if (priorityGarde.length === 0) {
          if (
            (nextShift === 0 || nextShift === '0') &&
            (lastLastShift === 1 || lastLastShift === '1')
          ) {
            priorityGarde.push(index);
          }
        }
      }
    }

    shuffle(priorityGarde);

    // Si isWorkingLastDay n'est pas vide et que des I ou Z de dispo, leur donner
    while (
      priorityGarde.length !== 0 &&
      (baseNeed.includes('Z') || baseNeed.includes('I'))
    ) {
      const index = priorityGarde.pop();
      const fonction = teamArr[index]['fonction'];
      if (
        baseNeed.includes('Z') &&
        !(
          fonction === 'Infirmier' ||
          fonction === 'ICUS' ||
          fonction === 'ASSC'
        )
      ) {
        const indexZ = baseNeed.indexOf('Z');
        teamArr[index]['pref'][j] = 'Z';
        baseNeed.splice(indexZ, 1);
      } else if (baseNeed.includes('I')) {
        const indexI = baseNeed.indexOf('I');
        teamArr[index]['pref'][j] = 'I';
        baseNeed.splice(indexI, 1);
      }
    }

    // Donner le reste des shifts besoin, random si non
    shuffle(baseNeed);

    isWorking = [];
    for (let i = 0; i < teamArr.length; i++) {
      const nurse = teamArr[i];
      const isWorkingToday = nurse['pref'][j];
      if (isWorkingToday === 1 || isWorkingToday === '1') {
        isWorking.push(i);
      }
    }

    if (baseNeed.includes('Z')) {
      const indexZ = baseNeed.indexOf('Z');
      baseNeed.splice(indexZ, 1);
      let randIndex;
      let fonction;
      let index;
      do {
        randIndex = Math.floor(Math.random() * isWorking.length);
        index = isWorking[randIndex];
        fonction = teamArr[index]['fonction'];
      } while (fonction !== 'Soignant');
      isWorking.splice(randIndex, 1);
      teamArr[index]['pref'][j] = 'Z';
    }

    while (isWorking.length > 0) {
      shuffle(isWorking);
      const indexIsWorking = isWorking.pop();
      const shift = baseNeed.pop();
      if (shift && shift !== '') {
        teamArr[indexIsWorking]['pref'][j] = shift;
      }
    }
  }
  return teamArr;
};

const generateShiftsJonquilles = (teamArr, daysInMonth) => {
  // chaque jour
  const nbDays = daysInMonth;
  for (let j = 0; j < nbDays; j++) {
    // Déterminer le nombre de personne qui travaillent ce jour-là
    const allShit = ['I', 'Z', 'G', 'C', 'Q', '1', 1];
    let nbWorking = 0;
    for (let i = 0; i < teamArr.length; i++) {
      const nurse = teamArr[i];
      const shift = nurse['pref'][j];
      // Vérifier si nurse travaille
      if (allShit.includes(shift)) {
        nbWorking++;
      }
    }
    // Définition des besoins de base
    let baseNeed = [];
    if (nbWorking > 6) {
      baseNeed = ['I', 'I', 'Z', 'G', 'G', 'C', 'Q'];
    } else {
      baseNeed = ['I', 'I', 'Z', 'G', 'C', 'Q'];
    }

    // Update besoin de base selon ce qui est déjà en place
    for (let i = 0; i < teamArr.length; i++) {
      const nurse = teamArr[i];
      const shift = nurse['pref'][j];
      // Vérifier si ce n'est pas 0 ou 1 ou horaire particulier
      // Si oui le supprimer des besoins de base
      let baseNeedIndex = baseNeed.indexOf(shift);
      if (shift === 'QP') {
        baseNeedIndex = baseNeed.indexOf('Q');
      }
      if (baseNeedIndex !== -1) {
        baseNeed.splice(baseNeedIndex, 1);
      }
    }

    // Faire la liste des infirmiers/icus/assc
    const lstOfDiplome = [];
    for (let i = 0; i < teamArr.length; i++) {
      const element = teamArr[i];
      const fonction = element['fonction'];
      const pref = element['pref'][j];
      if (
        (fonction === 'ICUS' ||
          fonction === 'Infirmier' ||
          fonction === 'ASSC') &&
        (pref === '1' || pref === 1)
      ) {
        lstOfDiplome.push(i);
      }
    }

    // Check si Q est tjrs présent et si oui le donner à un infirmier/assc/icus puis le supprimer
    if (baseNeed.includes('Q')) {
      const randIndex = Math.floor(Math.random() * lstOfDiplome.length);
      const randDiplomeIndex = lstOfDiplome[randIndex];
      teamArr[randDiplomeIndex]['pref'][j] = 'Q';
      const Qindex = baseNeed.indexOf('Q');
      baseNeed.splice(Qindex, 1);
      lstOfDiplome.splice(randIndex, 1);
    }

    // Faire la liste des gens qui ont un 1
    let isWorking = [];
    for (let i = 0; i < teamArr.length; i++) {
      const nurse = teamArr[i];
      const isWorkingToday = nurse['pref'][j];
      if (isWorkingToday === 1 || isWorkingToday === '1') {
        isWorking.push(i);
      }
    }

    // Faire la liste des gens dont c'est le dernier jour
    // ou qui ont une veille le lendemain
    const priorityGarde = [];
    for (let i = 0; i < isWorking.length; i++) {
      if (j + 1 <= nbDays && j - 2 >= 0) {
        const index = isWorking[i];
        const nextShift = teamArr[index]['pref'][j + 1];
        const lastLastShift = teamArr[index]['pref'][j - 2];
        if (nextShift === 'V') {
          priorityGarde.push(index);
        }
        if (priorityGarde.length === 0) {
          if (
            (nextShift === 0 || nextShift === '0') &&
            (lastLastShift === 1 || lastLastShift === '1')
          ) {
            priorityGarde.push(index);
          }
        }
      }
    }

    shuffle(priorityGarde);

    // Si isWorkingLastDay n'est pas vide et que des I ou Z de dispo, leur donner
    while (
      priorityGarde.length !== 0 &&
      (baseNeed.includes('Z') || baseNeed.includes('I'))
    ) {
      const index = priorityGarde.pop();
      const fonction = teamArr[index]['fonction'];
      if (
        baseNeed.includes('Z') &&
        !(
          fonction === 'Infirmier' ||
          fonction === 'ICUS' ||
          fonction === 'ASSC'
        )
      ) {
        const indexZ = baseNeed.indexOf('Z');
        teamArr[index]['pref'][j] = 'Z';
        baseNeed.splice(indexZ, 1);
      } else if (baseNeed.includes('I')) {
        const indexI = baseNeed.indexOf('I');
        teamArr[index]['pref'][j] = 'I';
        baseNeed.splice(indexI, 1);
      }
    }

    // Donner le reste des shifts besoin, random si non
    shuffle(baseNeed);

    isWorking = [];
    for (let i = 0; i < teamArr.length; i++) {
      const nurse = teamArr[i];
      const isWorkingToday = nurse['pref'][j];
      if (isWorkingToday === 1 || isWorkingToday === '1') {
        isWorking.push(i);
      }
    }

    if (baseNeed.includes('Z')) {
      const indexZ = baseNeed.indexOf('Z');
      baseNeed.splice(indexZ, 1);
      let randIndex;
      let fonction;
      let index;
      do {
        randIndex = Math.floor(Math.random() * isWorking.length);
        index = isWorking[randIndex];
        fonction = teamArr[index]['fonction'];
      } while (fonction !== 'Soignant');
      isWorking.splice(randIndex, 1);
      teamArr[index]['pref'][j] = 'Z';
    }

    while (isWorking.length > 0) {
      shuffle(isWorking);
      const indexIsWorking = isWorking.pop();
      const shift = baseNeed.pop();
      if (shift && shift !== '') {
        teamArr[indexIsWorking]['pref'][j] = shift;
      }
    }
  }
  return teamArr;
};

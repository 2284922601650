import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

// Loading and setting french as locale for dayjs
import dayjs from 'dayjs';
require('dayjs/locale/fr');
dayjs.locale('fr');

// Defining styles
const useStyles = makeStyles({
  table: {
    maxWidth: '100vw',
  },
  selectionTable: {
    minWidth: 200,
    maxWidth: 300,
  },
  tableContainer: {
    // maxHeight: '75vh',
  },
  tableCell: {
    fontWeight: 600,
    width: '15px',
    height: '15px',
    '&:hover': {
      background: 'yellow',
      cursor: 'pointer',
    },
  },
  cellWeekend: {
    background: 'whitesmoke',
  },
  cellHeader: {
    '&:hover': {
      background: 'grey',
      cursor: 'pointer',
    },
  },
  cellWeekendHeader: {
    background: 'gainsboro',
    '&:hover': {
      background: 'grey',
      cursor: 'pointer',
    },
  },
  cellHolidayHeader: {
    background: 'dodgerblue',
    '&:hover': {
      background: 'grey',
      cursor: 'pointer',
    },
  },
  cell1: {
    background: '#0A8754',
  },
  cellminus1: {
    background: '#F42C04',
  },
  cell_I: {
    background: '#ffd878',
  },
  cell_C: {
    background: '#60f0f0',
  },
  cell_Q: {
    background: '#4281A4',
    color: 'white',
  },
  cell_V: {
    background: '#111D4A',
    color: 'white',
  },
  cell_HS: {
    background: '#ccf6c8',
    color: 'black',
  },
  cell0: {
    color: 'lightslategrey',
    fontWeight: 300,
  },
  cell_Vac: {
    background: '#ea2c62',
    color: 'white',
  },
  cell_QP: {
    background: '#4281A4',
    color: '#F42C04',
  },
  cell_F: {
    background: '#949494',
    color: 'white',
  },
  dailyCoverNotMeet: {
    background: '#ff6961',
    color: 'white',
  },
  highOvertime: {
    background: '#ffae42 ',
    color: 'white',
  },
  dailyCoverExceeded: {
    background: '#77dd77 ',
    color: 'white',
  },
});

const useForceUpdate = () => {
  const [, setValue] = useState(0);
  return () => setValue((value) => ++value);
};

// SetPref componnent : display a table with the nurseList
const SetPref = ({
  nursesList,
  onClickHandler,
  selectedDate,
  dailyNeed,
  handleDailyNeedsChange,
  setBaseDueTime,
  isHolidaysArr,
  setIsHolidaysArr,
  setVMin,
  selectedShift,
  setSelectedShift,
  baseDueTimeArr,
  setBaseDueTimeArr,
  lastMonth,
}) => {
  const classes = useStyles(); // Styles
  const [dateArr, setDate] = useState([]); // array with the day of a month
  const [dueTime, setDueTime] = useState(); // base due time of a month
  const [showFonction, toogleShowFonction] = useState(false); // toogle for fonction column
  const [showPercentage, toogleShowPercentage] = useState(false); // toogle for percentage column
  const [showDueTime, toogleShowDueTime] = useState(false); // toogle for due time column
  const [showEffTime, toogleShowEffTime] = useState(false); // toogle for effective time column
  const [showSolde, toogleShowSolde] = useState(false); // toogle for solde column
  const [showVCMax, toogleShowVCMax] = useState(false); // toogle for VCMmax column
  const [showVCMin, toogleShowVCMin] = useState(false); // toogle for VCMmin column
  const [showVM, toogleShowVM] = useState(false); // toogle for VCM column
  const [showVmin, toogleShowVmin] = useState(false); // toogle for Vmin column
  const [showLastWeek, toogleShowLastWeek] = useState(false); // tootlge for last week column

  const [previousWeek, setPreviousWeek] = useState([]); // previous 7 days before the start of the month

  const forceUpdate = useForceUpdate();

  // Init componnent
  useEffect(() => {
    let baseDate = selectedDate.date(1);
    const monthIndex = baseDate.month();
    const extendedBaseDate = baseDate.month(monthIndex - 1);
    const oneWeekBefore = extendedBaseDate.date(
      extendedBaseDate.daysInMonth() - 6
    );

    // extra 7 days before
    const extraDaysArr = [];
    let j = oneWeekBefore.daysInMonth() - 6;
    while (j <= oneWeekBefore.daysInMonth()) {
      // fill dateArr
      extraDaysArr.push({
        D: oneWeekBefore.date(j).format('D'), // 1-31
        DD: oneWeekBefore.date(j).format('DD'), // 01-31
        dd: oneWeekBefore.date(j).format('dd'), // su-sa
        ddd: oneWeekBefore.date(j).format('ddd'), // sun - sat
        // check if a day is a weekend
        isWeekend:
          oneWeekBefore.date(j).format('d') % 7 === 6 ||
          oneWeekBefore.date(j).format('d') % 7 === 0,
      });
      j++;
    }
    setPreviousWeek(extraDaysArr);

    // Current month
    const dateArr = [];
    const isHolidaysArrTmp = [];
    let i = 1;
    while (i <= baseDate.daysInMonth()) {
      // fill dateArr
      dateArr.push({
        D: baseDate.date(i).format('D'), // 1-31
        DD: baseDate.date(i).format('DD'), // 01-31
        dd: baseDate.date(i).format('dd'), // su-sa
        ddd: baseDate.date(i).format('ddd'), // sun - sat
        // check if a day is a weekend
        isWeekend:
          baseDate.date(i).format('d') % 7 === 6 ||
          baseDate.date(i).format('d') % 7 === 0,
      });
      isHolidaysArrTmp.push(false);
      i++;
    }
    setDate(dateArr);
    setIsHolidaysArr(isHolidaysArrTmp);
    return () => {
      setDate([]);
    };
  }, [selectedDate, setIsHolidaysArr]);

  // Change Vmin for Veilleur to due time / 12
  useEffect(() => {
    if (dueTime > 0) {
      nursesList.forEach(({ id, isVeilleur }) => {
        if (isVeilleur) {
          const nbNightShiftTodo = (dueTimeNurse(id) / 12).toFixed(0);
          setVMin(id, nbNightShiftTodo);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dueTime]);

  // compute base due time (general)
  useEffect(() => {
    let bdt = 0;
    dateArr.forEach(({ isWeekend }, index) => {
      bdt++;
      if (isWeekend || isHolidaysArr[index]) {
        bdt--;
      }
    });
    setDueTime(bdt * 8.4);
    if (setBaseDueTime) {
      setBaseDueTime(bdt);
    }
  }, [setBaseDueTime, isHolidaysArr, dateArr, forceUpdate]);

  // Compute daily effective cover for day j (j start at 0)
  const cover = (j) => {
    const c = nursesList?.reduce((acc, cur) => {
      let z;
      switch (cur.pref[j]) {
        case 0:
          z = 0;
          break;
        case 1:
          z = 1;
          break;
        case -1:
        case -2:
          z = 0;
          break;
        case 'Vac':
        case 'HS':
        case 'F':
          z = 0;
          break;
        default:
          z = 1;
          break;
      }
      return acc + z;
    }, 0);
    return c;
  };

  // compute due time
  useEffect(() => {
    let tmpArr = [];
    for (let i = 0; i < nursesList.length; i++) {
      const nurse = nursesList[i];
      const percentage = nurse.percentage / 100;
      const dT = (dueTime * percentage).toFixed(2);
      tmpArr.push(parseFloat(dT));
    }
    setBaseDueTimeArr(tmpArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBaseDueTimeArr, dueTime]);

  const handleChangeBaseDueTimeArr = (event, index) => {
    let tmpArr = [...baseDueTimeArr];
    const newVal = event.target.value;
    const dT = parseFloat(newVal).toFixed(2);
    tmpArr[index] = dT;
    setBaseDueTimeArr(tmpArr);
  };

  // Compute due time for nurse with id = id
  // Default value
  const dueTimeNurse = (id) => {
    const percentage =
      nursesList.find((nurse) => nurse.id === id).percentage / 100;
    const dT = dueTime * percentage;
    return dT.toFixed(2);
  };

  // Compute monthly effective time for nurse with id = id
  const effTime = (id) => {
    const percentage =
      nursesList.find((nurse) => nurse.id === id).percentage / 100;
    const effectiveTime = nursesList
      .find((nurse) => nurse.id === id)
      .pref.reduce((acc, cur) => {
        let tmp = cur;
        let x;
        switch (tmp) {
          case 0:
            x = 0;
            break;
          case -1:
          case -2:
            x = 0;
            break;
          case 'I':
            x = 8 + 50 / 60;
            break;
          case 'Z':
          case 'G':
          case 'C':
          case 'HS':
          case 'QP':
            x = 8.4;
            break;
          case 'Vac':
            x = 8.4 * percentage;
            break;
          case 'Q':
            x = 9.15;
            break;
          case 'V':
          case 'V+':
            x = 12;
            break;
          case 'F':
            x = 0;
            break;
          default:
            x = 8.4;
        }
        return acc + x;
      }, 0);
    return effectiveTime.toFixed(2);
  };

  const getCssStyles = (val, isWeekend) => {
    let className = [];
    className.push(classes.tableCell);
    switch (val) {
      case 0:
        className.push(classes.cell0);
        break;
      case 1:
        className.push(classes.cell1);
        break;
      case -1:
      case -2:
        className.push(classes.cellminus1);
        break;
      case 'Z':
      case 'I':
        className.push(classes.cell_I);
        break;
      case 'V':
      case 'V+':
        className.push(classes.cell_V);
        break;
      case 'Q':
        className.push(classes.cell_Q);
        break;
      case 'QP':
        className.push(classes.cell_QP);
        break;
      case 'F':
        className.push(classes.cell_F);
        break;
      case 'HS':
        className.push(classes.cell_HS);
        break;
      case 'C':
      case 'G':
        className.push(classes.cell_C);
        break;
      case 'Vac':
        className.push(classes.cell_Vac);
        break;
      default:
        className.push(classes.cell1);
    }
    if (isWeekend) {
      className.push(classes.cellWeekend);
    }
    return className;
  };

  const getCssStylesSelected = (item) => {
    let className = [];
    className.push(classes.tableCell);
    switch (item) {
      case 0:
        className.push(classes.cell0);
        break;
      case 1:
        className.push(classes.cell1);
        break;
      case -1:
      case -2:
        className.push(classes.cellminus1);
        break;
      case 'Z':
      case 'I':
        className.push(classes.cell_I);
        break;
      case 'V':
      case 'V+':
        className.push(classes.cell_V);
        break;
      case 'Q':
        className.push(classes.cell_Q);
        break;
      case 'QP':
        className.push(classes.cell_QP);
        break;
      case 'F':
        className.push(classes.cell_F);
        break;
      case 'HS':
        className.push(classes.cell_HS);
        break;
      case 'C':
      case 'G':
        className.push(classes.cell_C);
        break;
      case 'Vac':
        className.push(classes.cell_Vac);
        break;
      default:
        className.push(classes.cell1);
    }
    return className;
  };

  const getCssHeaderStyles = (isWeekend, index) => {
    let className = [];
    if (isWeekend) {
      className.push(classes.cellWeekendHeader);
    }
    if (index >= 0 && isHolidaysArr[index]) {
      className.push(classes.cellHolidayHeader);
    }
    className.push(classes.cellHeader);
    return className;
  };

  const onClickHoliDays = (index) => {
    const tmp = [...isHolidaysArr];
    tmp[index] = !isHolidaysArr[index];
    setIsHolidaysArr(tmp);
    forceUpdate();
  };

  const onVminChange = (id, e) => {
    setVMin(id, e.target.value);
  };

  const onDailyNeedChange = (e, index) => {
    const value = parseInt(e.target.value);
    handleDailyNeedsChange(value, index);
    forceUpdate();
  };

  return (
    <Fragment>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={showFonction}
              onChange={() => toogleShowFonction(!showFonction)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          name="showFonction"
          label="Fonction"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showPercentage}
              onChange={() => toogleShowPercentage(!showPercentage)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          name="showPercentage"
          label="Pourcentage"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showDueTime}
              onChange={() => toogleShowDueTime(!showDueTime)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          name="showDueTime"
          label="Temps dû"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showEffTime}
              onChange={() => toogleShowEffTime(!showEffTime)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          name="showEffTime"
          label="Temps effectif"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showSolde}
              onChange={() => toogleShowSolde(!showSolde)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          name="showEffTime"
          label="Solde"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showVCMax}
              onChange={() => toogleShowVCMax(!showVCMax)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          name="showVCM"
          label="VCMax"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showVM}
              onChange={() => toogleShowVM(!showVM)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          name="showVM"
          label="VMax"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showVCMin}
              onChange={() => toogleShowVCMin(!showVCMin)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          name="showVCMin"
          label="VCMin"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showVmin}
              onChange={() => toogleShowVmin(!showVmin)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          name="showVMin"
          label="VMin"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showLastWeek}
              onChange={() => toogleShowLastWeek(!showLastWeek)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          name="showLastWeek"
          label="Mois précédent"
        />
      </FormGroup>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs>
          <Table
            size="small"
            aria-label="Selection  table"
            className={classes.selectionTable}
          >
            <TableBody>
              <TableRow>
                {[
                  'I',
                  'Z',
                  'C',
                  'G',
                  'Q',
                  'V',
                  'V+',
                  'HS',
                  'Vac',
                  'F',
                  1,
                  -1,
                  -2,
                ].map((item) => (
                  <TableCell
                    key={item}
                    onClick={() => {
                      setSelectedShift(item);
                    }}
                    className={getCssStylesSelected(item).join(' ')}
                  >
                    {item}
                  </TableCell>
                ))}
                <TableCell>Sélectionné</TableCell>
                <TableCell
                  className={getCssStylesSelected(selectedShift).join(' ')}
                >
                  {selectedShift}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Divider />
      <br />
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table
          stickyHeader
          className={classes.table}
          size="medium"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Prénom - Nom</TableCell>
              {showVCMax && <TableCell>VCMax</TableCell>}
              {showVM && <TableCell>VM</TableCell>}
              {showVCMin && <TableCell>VCmin</TableCell>}
              {showVmin && <TableCell>Vmin</TableCell>}
              {showPercentage && <TableCell>Pourcentage</TableCell>}
              {showFonction && <TableCell>Fonction</TableCell>}
              {showLastWeek &&
                previousWeek.map(({ DD, dd, isWeekend }, index) => (
                  <TableCell
                    className={getCssHeaderStyles(isWeekend, -1).join(' ')}
                    style={{ padding: '1px' }}
                    key={index}
                    align="center"
                  >
                    {dd} <br /> {DD}
                  </TableCell>
                ))}
              {dateArr.map(({ DD, dd, isWeekend }, index) => (
                <TableCell
                  className={getCssHeaderStyles(isWeekend, index).join(' ')}
                  style={{ padding: '1px' }}
                  key={index}
                  align="center"
                  onClick={() => {
                    onClickHoliDays(index);
                  }}
                >
                  {dd} <br /> {DD}
                </TableCell>
              ))}
              {showDueTime && <TableCell>Temps dû</TableCell>}
              {showEffTime && <TableCell>Temps effectif</TableCell>}
              {showSolde && <TableCell>Solde</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {nursesList?.map(
              (
                {
                  id,
                  name,
                  lastName,
                  percentage,
                  fonction,
                  VCM,
                  VM,
                  Vmin,
                  VCMin,
                },
                idx
              ) => (
                <TableRow key={id}>
                  <TableCell
                    align="left"
                    style={{ paddingTop: '1px', paddingBottom: '1px' }}
                  >{`${name} ${lastName}`}</TableCell>
                  {showVCMax && <TableCell>{VCM}</TableCell>}
                  {showVM && <TableCell>{VM}</TableCell>}
                  {showVCMin && <TableCell>{VCMin}</TableCell>}
                  {showVmin && (
                    <TableCell>
                      <TextField
                        size="medium"
                        defaultValue={Vmin}
                        type="number"
                        onChange={(e) => {
                          onVminChange(id, e);
                        }}
                      />
                    </TableCell>
                  )}
                  {showPercentage && <TableCell>{`${percentage}%`}</TableCell>}
                  {showFonction && <TableCell>{`${fonction}`}</TableCell>}
                  {showLastWeek &&
                    previousWeek.map(({ isWeekend }, index) => (
                      <TableCell
                        className={getCssStyles(
                          lastMonth ? lastMonth[idx].pref[index] : 0,
                          isWeekend
                        ).join(' ')}
                        onClick={(e) => {
                          console.log(
                            'Last week : nurse index : ',
                            idx,
                            'pref index : ',
                            index
                          );
                        }}
                        key={index}
                        align="center"
                      >
                        {lastMonth ? lastMonth[idx].pref[index] : 0}
                      </TableCell>
                    ))}
                  {dateArr.map(({ isWeekend }, index) => (
                    <TableCell
                      className={getCssStyles(
                        nursesList.find((nurse) => nurse.id === id).pref[index],
                        isWeekend
                      ).join(' ')}
                      onClick={(e) => {
                        onClickHandler(idx, index, e);
                      }}
                      key={index}
                      align="center"
                    >
                      {nursesList.find((nurse) => nurse.id === id).pref[index]}
                    </TableCell>
                  ))}
                  {showDueTime && (
                    <TableCell>
                      <TextField
                        type="number"
                        defaultValue={dueTimeNurse(id)}
                        onChange={(e) => {
                          handleChangeBaseDueTimeArr(e, idx);
                        }}
                      />
                    </TableCell>
                  )}
                  {showEffTime && <TableCell>{`${effTime(id)}h`}</TableCell>}
                  {showSolde && (
                    <TableCell
                      className={
                        effTime(id) - baseDueTimeArr[idx] > 16.8 ||
                        effTime(id) - baseDueTimeArr[idx] < -16.8
                          ? classes.highOvertime
                          : ''
                      }
                    >
                      {(effTime(id) - baseDueTimeArr[idx]).toFixed(2)}h
                    </TableCell>
                  )}
                </TableRow>
              )
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell scope="row">Besoins journaliers</TableCell>
              {showVCMax && <TableCell></TableCell>}
              {showVM && <TableCell></TableCell>}
              {showVCMin && <TableCell></TableCell>}
              {showVmin && <TableCell></TableCell>}
              {showPercentage && <TableCell></TableCell>}
              {showFonction && <TableCell></TableCell>}
              {showLastWeek &&
                previousWeek.map((_, index) => (
                  <TableCell key={index}></TableCell>
                ))}
              {dailyNeed.map((i, index) => (
                <TableCell style={{ padding: '5px' }} key={index}>
                  <TextField
                    type="number"
                    defaultValue={i}
                    onChange={(e) => {
                      onDailyNeedChange(e, index);
                    }}
                  />
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell scope="row">Couverture journalière</TableCell>
              {showVCMax && <TableCell></TableCell>}
              {showVM && <TableCell></TableCell>}
              {showVCMin && <TableCell></TableCell>}
              {showVmin && <TableCell></TableCell>}
              {showPercentage && <TableCell></TableCell>}
              {showFonction && <TableCell></TableCell>}
              {showLastWeek &&
                previousWeek.map((_, index) => (
                  <TableCell key={index}></TableCell>
                ))}
              {dateArr.map((_, index) => (
                <TableCell
                  className={
                    cover(index) < dailyNeed[index]
                      ? classes.dailyCoverNotMeet
                      : cover(index) > dailyNeed[index]
                      ? classes.dailyCoverExceeded
                      : ''
                  }
                  key={index}
                >
                  {cover(index)}
                </TableCell>
              ))}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default SetPref;

import React from 'react';
import Layout from './components/layout/Layout';

const About = () => {
  return (
    <Layout navName="About">
      <h2>Author</h2>
      <p>
        This software was developed by Luu Quoc Bao as part of a bachelor's
        thesis at the University of Fribourg.
      </p>
      <h2>Licence</h2>
      <div>
        This software is provided 'as-is', without any express or implied
        warranty. In no event will the authors be held liable for any damages
        arising from the use of this software.
        <br />
        Permission is granted to anyone to use this software for any purpose,
        including commercial applications, and to alter it and redistribute it
        freely, subject to the following restrictions:
        <br />
        <ol>
          <li>
            The origin of this software must not be misrepresented; you must not
            claim that you wrote the original software. If you use this software
            in a product, an acknowledgment in the product documentation would
            be appreciated but is not required.
          </li>
          <li>
            Altered source versions must be plainly marked as such, and must not
            be misrepresented as being the original software.
          </li>
          <li>
            This notice may not be removed or altered from any source
            distribution.
          </li>
        </ol>
      </div>
    </Layout>
  );
};

export default About;

import React, { useState, Fragment } from 'react';
import CustomSnackbar from './CustomSnackbar';

import BasicEmployeeForm from './BasicEmployeeForm';
import { projectFirestore } from '../firebase/config';

const AddEmployeeForm = () => {
  const [tmpEmployee, setTmpEmployee] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleAjouter = () => {
    console.log('To Add : ', tmpEmployee);
    const collectionRef = projectFirestore.collection('employees');
    collectionRef.add(tmpEmployee);
    showSnackbar();
  };

  const showSnackbar = () => {
    setOpenSnackbar(true);
    setTimeout(function () {
      setOpenSnackbar(false);
    }, 3000);
  };

  return (
    <Fragment>
      <h2> Ajouter un employé</h2>
      <BasicEmployeeForm
        handleSubmit={handleAjouter}
        submitName="Ajouter"
        setTmpEmployee={setTmpEmployee}
      />
      <CustomSnackbar
        type="success"
        msg="Employé ajouté !"
        isOpen={openSnackbar}
      />
    </Fragment>
  );
};

export default AddEmployeeForm;

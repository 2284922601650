import React from 'react';
import MiniDrawer from './NavBar';

export default function Layout({ children, navName }) {
  return (
    <div>
      <MiniDrawer navName={navName}>{children}</MiniDrawer>
    </div>
  );
}
